import GlideRecord from "helpers/GlideRecord";
import {
  CHAT_GET_CONTACTS,
  CHAT_GET_CONTACTS_SUCCESS,
  CHAT_GET_CONTACTS_ERROR,
  CHAT_GET_CONVERSATIONS,
  CHAT_GET_CONVERSATIONS_SUCCESS,
  CHAT_GET_CONVERSATIONS_ERROR,
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_CREATE_CONVERSATION,
  CHAT_SEARCH_CONTACT,
  CHAT_CHANGE_CONVERSATION,
  CHAT_GET_GROUPS,
  CHAT_GET_GROUPS_ERROR,
  CHAT_GET_GROUPS_SUCCESS,
} from "../contants";

const INIT_STATE = {
  allContacts: null,
  contacts: null,
  conversations: null,
  error: "",
  searchKeyword: "",
  loadingContacts: false,
  loadingConversations: false,
  loadingGroups: false,
  groups: null,

  currentUser: null,
  selectedUser: null,
  selectedUserId: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHAT_GET_CONTACTS:
      return { ...state, loadingContacts: false };
    case CHAT_GET_CONTACTS_SUCCESS:
      return {
        ...state,
        loadingContacts: true,
        allContacts: action.payload.contacts,
        contacts: action.payload.contacts,
        currentUser: action.payload.currentUser,
      };
    case CHAT_GET_CONTACTS_ERROR:
      return { ...state, loadingContacts: false, error: action.payload };

    case CHAT_GET_GROUPS:
      return { ...state, loadingGroups: false };
    case CHAT_GET_GROUPS_SUCCESS:
      return {
        ...state,
        loadingGroups: true,
        groups: action.payload.groups,
      };
    case CHAT_GET_GROUPS_ERROR:
      return { ...state, loadingGroups: false, error: action.payload };

    case CHAT_GET_CONVERSATIONS:
      return { ...state, loadingConversations: false };
    case CHAT_GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loadingConversations: true,
        conversations: action.payload.conversations,
        selectedUserId: action.payload.selectedUser,
      };
    case CHAT_GET_CONVERSATIONS_ERROR:
      return { ...state, loadingConversations: false, error: action.payload };
    case CHAT_CHANGE_CONVERSATION:
      console.log(action.payload, state.allContacts);
      let selectedUser = state.allContacts.find(
        (x) => parseInt(x.id) === action.payload
      );
      console.log(selectedUser);
      if (!selectedUser) {
        selectedUser = state.groups?.find(
          (g) => parseInt("1010" + g.id) == action.payload
        );
        if (selectedUser) selectedUser.isGroup = true;
      }

      console.log({ ...state, selectedUser });
      return { ...state, selectedUser };

    case CHAT_ADD_MESSAGE_TO_CONVERSATION:
      return { ...state };
    case CHAT_CREATE_CONVERSATION:
      return { ...state };
    case CHAT_SEARCH_CONTACT:
      if (action.payload === "") {
        return { ...state, contacts: state.allContacts };
      }
      // eslint-disable-next-line no-case-declarations
      const keyword = action.payload.toLowerCase();
      // eslint-disable-next-line no-case-declarations
      const searchedContacts = state.allContacts.filter(
        (item) => item.name.toLowerCase().indexOf(keyword) > -1
      );
      return { ...state, contacts: searchedContacts };

    default:
      return { ...state };
  }
};
