import { apiURL } from "./api";

export const Authentication = async (email, password) => {
  return await apiURL
    .get("auth.php?email=" + email + "&pass=" + password)
    .then(async function (response) {
      console.log(response);
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
};

export const impersonateUser = async (email, encriptedPass) => {
  return await apiURL
    .get(
      "auth.php?pass=impersionation&email=" + email + "&epass=" + encriptedPass
    )
    .then(async function (response) {
      console.log(response);
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
};

export const sendPasswordResetEmail = async (email) => {
  return await apiURL
    .get("auth.php?email=" + email + "&reset=1")
    .then(async function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
};

export const confirmPasswordReset = async (resetPasswordCode, newPassword) => {
  return await apiURL
    .get(
      "auth.php?resetPasswordCode=" +
        resetPasswordCode +
        "&newPassword=" +
        newPassword
    )
    .then(async function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
};
