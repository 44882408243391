// import firebase from 'firebase/app';
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";
// import 'firebase/auth';
// import 'firebase/database';

import { firebaseConfig } from 'constants/defaultValues';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// firebase.initializeApp(firebaseConfig);

// const auth = firebase.auth();
// const database = firebase.database();
const db = getFirestore(app);

// export { auth, database};
export { db, auth };
