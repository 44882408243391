import { apiURLAuth } from "./api";

export const Conversation = async () => {
  return await apiURLAuth
    .get("chat.php")
    .then(async function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
};

export const Users = async () => {
  return await apiURLAuth
    .get("chatUsers.php")
    .then(async function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
};
