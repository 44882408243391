import { apiURLAuth } from "./api";

export default function GlideRecord(table) {
  return {
    get: async (id) => {
      const response = await apiURLAuth.get(
        `/record.php?id=${id}&table=${table}`
      );

      return response.data;
    },
    getAll: async () => {
      const response = await apiURLAuth.get(`/record.php?table=${table}`);

      return response.data;
    },
    getByQuery: async (query) => {
      const response = await apiURLAuth.get(
        `/record.php?table=${table}&query=${query}`
      );

      return response.data;
    },
    insert: async (record) => {
      const response = await apiURLAuth.post(
        `/record.php?table=${table}`,
        record
      );

      return response.data;
    },
    delete: async (id) => {
      const response = await apiURLAuth.delete(
        `/record.php?id=${id}&table=${table}`
      );

      return response.data;
    },
    deleteByQuery: async (query) => {
      const response = await apiURLAuth.delete(
        `/record.php?query=${query}&table=${table}`
      );

      return response.data;
    },
    update: async (id, record) => {
      const response = await apiURLAuth.put(
        `/record.php?table=${table}&id=${id}`,
        record
      );

      return response.data;
    },
    updateByQuery: async (query, record) => {
      const response = await apiURLAuth.put(
        `/record.php?table=${table}&query=${query}`,
        record
      );

      return response.data;
    },
  };
}
